import { DefaultTeaser as ChameleonDefaultTeaser } from '@mediahuis/chameleon-react';
import type { ComponentType, FC, ReactElement } from 'react';
import cx from 'classnames';
import { defaultTeaserImageRatio } from '@hubcms/brand';

import { ImageAspectRatio } from '@hubcms/domain-images';

import type { DefaultTeaserProps as ChameleonDefaultTeaserProps } from '../../domain/chameleon/default-teaser-props';
import type { DefaultTeaserPlaceholderProps } from './types';
import styles from './default-teaser-placeholder.module.scss';

// eslint-disable-next-line react/jsx-no-useless-fragment
const EmptyImage: FC = () => <></>;

// TODO: Chameleon might create this instead
const DefaultTeaserPlaceholder: FC<DefaultTeaserPlaceholderProps> = ({
  imageFormat = defaultTeaserImageRatio,
  hasDuration,
  hasLabel,
  isImageHidden,
  hasIntro,
  headerLevel,
  isHighlighted,
  hasInset,
  ...props
}) => {
  let teaserImage: ReactElement | null = null;
  if (!isImageHidden) {
    // This lets Chameleon Teaser set the placeholder, so we don't have to
    teaserImage = <EmptyImage />;
  }

  const imageAspectRatio = ImageAspectRatio[imageFormat];

  const Component = ChameleonDefaultTeaser as ComponentType<ChameleonDefaultTeaserProps>;
  return (
    <Component
      articleType="article"
      title="　"
      introduction={hasIntro ? '　' : ''}
      link=""
      label={hasLabel ? '　' : ''}
      subLabel={hasLabel ? '　　' : ''}
      duration={hasDuration ? '　　' : ''}
      titleTagName={`h${headerLevel}` as ChameleonDefaultTeaserProps['titleTagName']}
      highlight={isHighlighted}
      {...props}
      inset={hasInset}
      imageOptions={{ aspectRatio: `${imageAspectRatio.width}:${imageAspectRatio.height}` }}
      image={teaserImage}
      className={cx(styles.teaserPlaceholder, { [styles.withLabel]: hasLabel })}
    />
  );
};

export default DefaultTeaserPlaceholder;
